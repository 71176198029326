<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_user_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_user_search"
              v-model="tableMerchant.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          order="3"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="primary"
            @click="onCreateMerchant"
          >
            Add Merchant
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableMerchant"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableMerchant.perPage"
            :current-page="tableMerchant.currentPage"
            :items="tableMerchantProvider"
            :fields="tableMerchant.fields"
            :sort-by.sync="tableMerchant.sortBy"
            :sort-desc.sync="tableMerchant.sortDesc"
            :sort-direction="tableMerchant.sortDirection"
            :filter="tableMerchant.filter"
            :filter-included-fields="tableMerchant.filterOn"
            :busy="tableMerchant.busy"
          >
            <template #cell(index)="{ index }">
              {{ tableNumber(tableMerchant, index) }}
            </template>

            <template #cell(active)="{ value }">
              {{ Number(value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(is_vip)="{ value }">
              {{ Number(value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(fee)="{ value }">
              <div class="text-right">
                {{ numberFormatter(value) }}
              </div>
            </template>

            <template #cell(name)="{ item }">
              <div class="text-wrap text-md-nowrap">
                {{ item.first_name }} {{ item.last_name }}
              </div>
            </template>

            <template #cell()="{ value }">
              <div class="text-wrap text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="primary"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onEditMerchant(item)"
                >
                  Edit
                </b-button>
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="onViewCredentials(item)"
                >
                  Wallets & Credentials
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-2 px-1"
        >
          <b-select
            v-model="tableMerchant.perPage"
            :options="tableMerchant.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-2 px-1"
        >
          <b-pagination
            v-model="tableMerchant.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableMerchant.totalRows"
            :per-page="tableMerchant.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-merchant"
      size="xl"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
      :title="ModalMerchantTitle"
    >
      <b-container
        class="h-100 py-1"
        style="overflow-y: scroll"
        fluid
      >
        <ValidationObserver
          ref="formMerchant"
        >
          <form
            role="form"
            novalidate
            @submit.prevent
          >
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="first name"
                  vid="first_name"
                  rules="required|max:50"
                >
                  <b-form-group
                    label="First Name"
                    label-for="first_name"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="first_name"
                      v-model="merchant.first_name"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter first name here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="last name"
                  vid="last_name"
                  rules="required|max:50"
                >
                  <b-form-group
                    label="Last Name"
                    label-for="last_name"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="last_name"
                      v-model="merchant.last_name"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter last name here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="organization"
                  vid="organization"
                  rules="required|max:100"
                >
                  <b-form-group
                    label="Organization"
                    label-for="organization"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="organization"
                      v-model="merchant.organization"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter organization here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="email|max:50"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="email"
                      v-model="merchant.email"
                      type="email"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter email here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="mobile number"
                  vid="mobile_number"
                  rules="required|min:11|max:11"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="mobile_number"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="mobile_number"
                      v-model="merchant.mobile_number"
                      type="text"
                      autocomplete="off"
                      maxlength="11"
                      minlength="11"
                      placeholder="enter mobile number here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="prefix"
                  vid="prefix"
                  rules="required|min:3|max:3"
                >
                  <b-form-group
                    label="Prefix"
                    label-for="prefix"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="prefix"
                      v-model="merchant.prefix"
                      type="text"
                      autocomplete="off"
                      maxlength="3"
                      minlength="3"
                      placeholder="enter prefix here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                class="mb-5"
              />

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="source account"
                  vid="source_account"
                  rules="required|max:50"
                >
                  <b-form-group
                    label="Source Account"
                    label-for="source_account"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="source_account"
                      v-model="merchant.source_account"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter source account here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="api rate limit"
                  vid="api_rate_limit"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="API Rate Limit"
                    label-for="api_rate_limit"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="api_rate_limit"
                      v-model="merchant.api_rate_limit"
                      type="number"
                      autocomplete="off"
                      class="text-right"
                      placeholder="enter api rate limit here"
                      min="0"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="fee"
                  vid="fee"
                  rules="required|currency:1"
                >
                  <b-form-group
                    label="Fee"
                    label-for="fee"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fee"
                      v-model="maskedMerchant.percentage_fee"
                      v-mask="currencyMask"
                      type="text"
                      autocomplete="off"
                      class="text-right"
                      placeholder="enter fee here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                class="mb-5"
              />

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required|max:50"
                >
                  <b-form-group
                    label="Username"
                    label-for="username"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="username"
                      v-model="merchant.username"
                      type="text"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter username here"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  :rules="vPassword"
                >
                  <b-form-group
                    label="Password"
                    label-for="password"
                    description="Leave blank when not changing password."
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-input
                        id="password"
                        v-model="merchant.password"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="enter password here"
                        :type="passwordType"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <b-input-group-append>
                        <b-button
                          class="pl-2 pr-1"
                          variant="primary"
                          style="border-radius: 0px 5px 5px 0px"
                          :disabled="state.busy"
                          @click="togglePasswordType"
                        >
                          <i
                            v-if="state.password_visible"
                            class="ri-eye-line h5"
                          />
                          <i
                            v-else
                            class="ri-eye-off-line h5"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="password confirmation"
                  vid="password_confirmation"
                  :rules="vPasswordConfirmation"
                >
                  <b-form-group
                    label="Password Confirmation"
                    label-for="password_confirmation"
                    description="Leave as blank when not changing password."
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="password_confirmation"
                      v-model="merchant.password_confirmation"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter password confirmation here"
                      :type="passwordType"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
              >
                <b-form-group
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="is_vip"
                    v-model="merchant.is_vip"
                    switch
                    value="1"
                    name="is_vip"
                    unchecked-value="0"
                    :disabled="state.busy"
                  >
                    Is VIP?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col
                v-if="state.editing"
                cols="12"
              >
                <b-form-group
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="is_active"
                    v-model="merchant.active"
                    switch
                    value="1"
                    name="is_active"
                    unchecked-value="0"
                    :disabled="state.busy"
                  >
                    Is Active?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

            </b-row>
          </form>
        </ValidationObserver>

      </b-container>
      <template #modal-footer="{cancel}">
        <b-button
          variant="primary"
          :disabled="state.busy"
          @click="onValidateMerchant"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close <span class="d-none d-md-inline-block">Window</span>
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-merchant-details"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        v-model="state.currentTabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-file-invoice h4 px-1" />
              <b class="is-tab-active">Wallets</b>
            </div>
          </template>
          <Wallets
            :tab-index="state.currentTabIndex"
            :merchant="merchant"
          />
        </b-tab>
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-file-invoice h4 px-1" />
              <b class="is-tab-active">Webhook Urls</b>
            </div>
          </template>
          <WebhookUrls
            :tab-index="state.currentTabIndex"
            :merchant="merchant"
          />
        </b-tab>
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-file-invoice h4 px-1" />
              <b class="is-tab-active">Client Keys</b>
            </div>
          </template>
          <Credentials
            :tab-index="state.currentTabIndex"
            :merchant="merchant"
          />
        </b-tab>
      </b-tabs>
      <template #modal-footer="{cancel}">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close <span class="d-none d-md-inline-block">Window</span>
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SAdminMerchant } from '@/services'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

import Wallets from '@/views/admin/merchants/Wallets'
import WebhookUrls from '@/views/admin/merchants/WebhookUrls'
import Credentials from '@/views/admin/merchants/Credentials'

export default {
  name: 'AdminMerchants',

  middleware: ['auth', 'admin'],

  components: {
    Credentials,
    Wallets,
    WebhookUrls
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Merchants'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        currentTabIndex: 0,
        password_visible: false
      },
      currencyMask: createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        decimalLimit: 2
      }),
      maskedMerchant: {
        percentage_fee: null
      },
      merchant: {
        id: null,
        first_name: null,
        last_name: null,
        organization: null,
        email: null,
        mobile_number: null,
        prefix: null,
        username: null,
        password: null,
        password_confirmation: null,
        source_account: null,
        api_rate_limit: null,
        percentage_fee: null,
        is_vip: 0,
        active: 1
      },
      tableMerchant: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 11, key: 'action', class: 'text-center min-w-150px' },
          { mobile: 1, key: 'created_at', class: 'min-w-200px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'name', class: 'min-w-300px' },
          { mobile: 3, key: 'organization', class: 'min-w-300px' },
          { mobile: 4, key: 'username', class: 'min-w-200px' },
          { mobile: 5, key: 'email', class: 'min-w-300px' },
          { mobile: 6, key: 'prefix', class: 'min-w-70px' },
          { mobile: 7, key: 'percentage_fee', class: 'min-w-150px' },
          { mobile: 8, key: 'is_vip', label: 'VIP', class: 'text-center min-w-70px' },
          { mobile: 9, key: 'active', class: 'text-center min-w-70px' },
          { mobile: 10, key: 'updated_at', class: 'min-w-200px', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalMerchantTitle () {
      return this.state.editing ? 'Edit Merchant' : 'Add Merchant'
    },

    vPassword () {
      return `${this.state.editing ? '' : 'required|'}min:8`
    },

    vPasswordConfirmation () {
      return `${this.state.editing ? '' : 'required|'}confirmed:password`
    },

    passwordType () {
      return this.state.password_visible ? 'text' : 'password'
    }
  },

  watch: {
    'maskedMerchant.percentage_fee' (percentageFee) {
      const transformToNumericValue = percentageFee && percentageFee.length ? percentageFee.replace(/[^\d.]/g, '') : percentageFee
      this.merchant.percentage_fee = transformToNumericValue ? parseFloat(transformToNumericValue).toFixed(2) : null
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    togglePasswordType () {
      this.state.password_visible = !this.state.password_visible
    },

    async tableMerchantProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableMerchant.busy = true
      return await SAdminMerchant.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableMerchant.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableMerchant.busy = false
      })
    },

    onCreateMerchant () {
      this.state.editing = false
      this.merchant.id = null
      this.merchant.first_name = null
      this.merchant.last_name = null
      this.merchant.organization = null
      this.merchant.email = null
      this.merchant.mobile_number = null
      this.merchant.prefix = null
      this.merchant.source_account = null
      this.merchant.api_rate_limit = null
      this.maskedMerchant.percentage_fee = null
      this.merchant.username = null
      this.merchant.password = null
      this.merchant.password_confirmation = null
      this.merchant.is_vip = 0
      this.merchant.active = 1
      this.$bvModal.show('modal-merchant')
    },

    onEditMerchant (merchant) {
      this.state.editing = true
      this.merchant.id = merchant.id
      this.merchant.first_name = merchant.first_name
      this.merchant.last_name = merchant.last_name
      this.merchant.organization = merchant.organization
      this.merchant.email = merchant.email
      this.merchant.mobile_number = merchant.mobile_number
      this.merchant.prefix = merchant.prefix
      this.merchant.source_account = merchant.source_account
      this.merchant.api_rate_limit = merchant.api_rate_limit
      this.maskedMerchant.percentage_fee = merchant.percentage_fee
      this.merchant.username = merchant.username
      this.merchant.password = null
      this.merchant.password_confirmation = null
      this.merchant.is_vip = merchant.is_vip
      this.merchant.active = merchant.active
      this.$bvModal.show('modal-merchant')
    },

    async onValidateMerchant (event) {
      event.preventDefault()
      await this.$refs.formMerchant.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your Changes on this Merchant?</h6>'
                }
                return '<h6>Create this Merchant?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutMerchant()
                }
                return this.onPostMerchant()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostMerchant () {
      return new Promise(resolve => {
        SAdminMerchant.post(this.merchant).then(
          ({ data: { message } }) => {
            this.$refs.tableMerchant.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-merchant')
            })
            resolve(message)
          }
        ).catch(({ message }) => {
          this.$refs.formMerchant.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutMerchant () {
      return new Promise(resolve => {
        SAdminMerchant.put(this.merchant).then(
          ({ data: { message, merchant } }) => {
            const tableMerchantRow = QUERY.find(this.$refs.tableMerchant.localItems, { id: merchant.id })
            if (tableMerchantRow) {
              tableMerchantRow.first_name = merchant.first_name
              tableMerchantRow.last_name = merchant.last_name
              tableMerchantRow.organization = merchant.organization
              tableMerchantRow.email = merchant.email
              tableMerchantRow.mobile_number = merchant.mobile_number
              tableMerchantRow.prefix = merchant.prefix
              tableMerchantRow.source_account = merchant.source_account
              tableMerchantRow.api_rate_limit = merchant.api_rate_limit
              tableMerchantRow.percentage_fee = merchant.percentage_fee
              tableMerchantRow.username = merchant.username
              tableMerchantRow.is_vip = merchant.is_vip
              tableMerchantRow.active = merchant.active
              tableMerchantRow.updated_at = merchant.updated_at
            }
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-merchant')
            })
            resolve(merchant)
          }
        ).catch(({ message }) => {
          this.$refs.formMerchant.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    onViewCredentials (merchant) {
      this.state.editing = false
      this.merchant.id = merchant.id
      this.merchant.first_name = merchant.first_name
      this.merchant.last_name = merchant.last_name
      this.merchant.organization = merchant.organization
      this.merchant.email = merchant.email
      this.merchant.mobile_number = merchant.mobile_number
      this.merchant.prefix = merchant.prefix
      this.merchant.source_account = merchant.source_account
      this.merchant.api_rate_limit = merchant.api_rate_limit
      this.maskedMerchant.percentage_fee = merchant.percentage_fee
      this.merchant.username = merchant.username
      this.merchant.password = null
      this.merchant.password_confirmation = null
      this.merchant.is_vip = merchant.is_vip
      this.merchant.active = merchant.active
      this.$bvModal.show('modal-merchant-details')
    }
  }
}
</script>
